<template>
  <el-input-number v-model="childValue" :min="min" :max="max" :step="step" @change="handleChange" />
</template>

<script>
export default {
  name: "numberInput",
  props: {
    min: {
      Type: Number,
      Default: 0,
    },
    step: {
      Type: Number,
      Default: 1,
    },
    total:{
      Type:Number,
      Default:0,
      Require:true
    },
    numberValue:{
      Type:Number,
      Default:1,
      Require:true
    }
  },
  data(){
    return {
      childValue:this.numberValue,
      max:9999
    }
  },
  computed:{

  },
  watch:{
    numberValue:{
      deep:true,
      immediate:true,
      handler(val){
        this.childValue = val
        this.$forceUpdate()
      }
    },
    min:{
      deep:true,
      immediate:true,
      handler(){
        this.$forceUpdate()
      }
    },
    step:{
      deep:true,
      immediate:true,
      handler(){
        this.$forceUpdate()
      }
    },
    total:{
      deep:true,
      immediate:true,
      handler(val){
        if (val === this.step) this.max = val
        this.$forceUpdate()
      }
    },
  },
  methods: {
    updateNumber(){
      this.$emit("updateNumber", this.childValue);
    },
    handleChange(val) {
      if (val >= this.total) {
        this.max = this.total
        this.childValue = this.total
      }
      if (val < this.total && val % this.step !== 0){
        //不符合规格时候增减，取大于当前值的最小倍数
        this.childValue = Math.ceil(val/this.step) * this.step
        this.max = this.$options.data().max
      }

      this.updateNumber()
      this.$forceUpdate()
    },
  },
};
</script>

<style scoped lang="scss">

</style>
